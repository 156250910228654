import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIssuers } from '../../../store/action_creators/paymentMethods.actions';
import { CommerceToAdd, PaymentMethod, RootState, SubBusiness } from '../../../store/config/types';
import SubBusinessPaymentMethodForm from '../../forms/SubBusinessPaymentMethodForm/SubBusinessPaymentMethodForm';

interface SubBusinessDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  paymentMethod: PaymentMethod | null;
  addPaymentMethodToSubBusiness: (paymentMethod: CommerceToAdd, isAlreadyInState: boolean) => void;
  selectedSubBusiness: SubBusiness;
}

function SubBusinessDialog({
  open,
  setOpen,
  paymentMethod,
  addPaymentMethodToSubBusiness,
  selectedSubBusiness,
}: SubBusinessDialogProps) {
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state: RootState) => state.paymentMethods);

  const closeDialog = () => setOpen(false);

  useEffect(() => {
    if (
      !paymentMethods.loadingIssuers &&
      paymentMethods.issuers === null &&
      paymentMethods.issuersErrorMessage === null
    ) {
      dispatch(fetchIssuers());
    }
  }, [paymentMethods.loadingIssuers, paymentMethods.issuers, paymentMethods.issuersErrorMessage, dispatch]);

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      {paymentMethods.issuers && (
        <>
          <DialogContent classes={{ root: 'dialog-content' }}>
            <SubBusinessPaymentMethodForm
              paymentMethod={paymentMethod}
              addPaymentMethodToSubBusiness={addPaymentMethodToSubBusiness}
              handleSuccess={closeDialog}
              selectedSubBusiness={selectedSubBusiness}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default SubBusinessDialog;
