import { Divider, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyHelper } from '../../../../helpers/currencyHelper';
import { numberHelper } from '../../../../helpers/numberHelper';
import { getBusinessSubBusinesses } from '../../../../store/action_creators/business.actions';
import { LinkTypeEnum, PaymentTypeEnum } from '../../../../store/config/enums';
import { Link, RootState, SplitLinkRequestValues } from '../../../../store/config/types';
import styles from '../../../../styles/_variables.module.scss';
import SplitLinkPaymentInput from './SplitLinkPaymentInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      textAlign: 'end',
      [theme.breakpoints.down('md')]: {
        textAlign: 'start',
      },
    },
    totalRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      gap: '0.625rem',
      '& div': {
        fontSize: '0.875rem',
        fontWeight: styles.semiBoldTextWeight,
      },
      '& p': {
        fontSize: '1.0625rem',
        fontWeight: styles.semiBoldTextWeight,
        margin: '0.25rem 0',
        color: styles.secondaryMain,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    amountDetail: {
      color: styles.blackText,
    },
    divider: {
      width: '100%',
      margin: '1rem 0',
    },
  }),
);

interface SplitLinkPaymentInputsProps {
  linkType: LinkTypeEnum;
  link?: Link;
  paymentType?: PaymentTypeEnum;
}

function SplitLinkPaymentInputs({ link }: SplitLinkPaymentInputsProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.auth.account);
  const subBusinesses = useSelector((state: RootState) => state.business.subBusinesses);
  const { values } = useFormikContext<SplitLinkRequestValues>();
  const currencyToken = currencyHelper.getCurrencyToken(values.currency);
  const [availableOptions, setAvailableOptions] = useState<{ value: string; id: string }[]>([]);

  useEffect(() => {
    if (account && account.business.id) {
      dispatch(getBusinessSubBusinesses(account.business.id));
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (subBusinesses && subBusinesses.length > 0) {
      const options = subBusinesses.map((subBusiness) => ({
        id: subBusiness.id.toString(),
        value: subBusiness.name,
      }));

      setAvailableOptions(options);
    }
  }, [subBusinesses]);

  const { totalAmount, subTotalAmount, totalVat } = useMemo(() => {
    const subTotalAmountOne = +values.amount / (1 + +values.vatRate / 100);
    const subTotalAmountTwo = +values.subBusinessAmount / (1 + +values.subBusinessVatRate / 100);
    const subTotalAmount = subTotalAmountOne + subTotalAmountTwo;

    const totalAmount = +values.amount + +values.subBusinessAmount;

    const totalVat = totalAmount - subTotalAmount;

    return { totalAmount, subTotalAmount, totalVat };
  }, [values.amount, values.subBusinessAmount, values.subBusinessVatRate, values.vatRate]);

  return (
    <>
      <SplitLinkPaymentInput
        valueId={account?.business.id?.toString() || ''}
        availableSelectOptions={[
          { id: account?.business.id?.toString() || '', value: account?.business.prettyName || '' },
        ]}
        link={link}
        isBusiness
      />
      <Divider orientation="horizontal" className={classes.divider} />
      <SplitLinkPaymentInput
        valueId={values.subBusinessId || ''}
        availableSelectOptions={availableOptions}
        link={link}
      />
      <Divider orientation="horizontal" className={classes.divider} />
      <div className={classes.totalContainer}>
        <div className={classes.totalRow}>
          <div>Total a pagar</div>
          <p>
            {currencyToken} {numberHelper.getNumberWithDots(totalAmount)}
          </p>
        </div>
        <div className={classes.amountDetail}>
          {currencyToken} {numberHelper.getNumberWithDots(subTotalAmount)} + {currencyToken}{' '}
          {numberHelper.getNumberWithDots(totalVat)} (IVA)
        </div>
      </div>
    </>
  );
}

export default SplitLinkPaymentInputs;
