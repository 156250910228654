import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Forbidden } from '../components/Forbidden';
import { SubUser } from '../components/forms/SubUser';
import { Header } from '../components/Header';
import { Loading } from '../components/Loading';
import { PublicHeader } from '../components/PublicHeader';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import { urlHelper } from '../helpers/urlHelper';
import { ConfirmEmail } from '../screens/ConfirmEmail';
import { getAccount } from '../store/action_creators/auth.actions';
import { LinkTypeEnum, UserTypeEnum } from '../store/config/enums';
import { RootState } from '../store/config/types';
import { Business } from './Business';
import { ChangePassword } from './ChangePassword.tsx';
import { Configuration } from './Configuration';
import { ConfirmResetPassword } from './ConfirmResetPassword';
import { Contact } from './Contact';
import { Dashboard } from './Dashboard';
import { FAQs } from './FAQs';
import { Home } from './Home';
import { Landing } from './Landing';
import { Link } from './Link';
import { Links } from './Links';
import { Login } from './Login';
import Maintenance from './Maintenance/Maintenance';
import { ManageClients } from './ManageClients';
import { ManageClientUsers } from './ManageClientUsers';
import { OnTheFlyPayments } from './OnTheFly';
import { PaymentDetail } from './PaymentDetail';
import { Payments } from './Payments';
import { PlexoPayment } from './PlexoPayment';
import { Profile } from './Profile';
import { Redirect } from './Redirect';
import { Register } from './Register';
import FirstPaymentMethod from './Register/FirstPaymentMethod';
import { RequestIssuers } from './RequestIssuers';
import { ResetPassword } from './ResetPassword';
import { Result } from './Result';
import { SplitPayments } from './SplitPayments';
import { SubBusiness } from './SubBusiness';
import { TermsAndConditions } from './TermsAndConditions';
import { UnconfirmedUsers } from './UnconfirmedUsers';
import { Users } from './Users';

function Main() {
  const isMaintenanceMode = false;
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const [isPrivatePage, setIsPrivatePage] = useState<boolean>(urlHelper.isPrivatePage(location.pathname));
  const [isHome, setIsHome] = useState<boolean>(location.pathname === '/');
  const [noPaymentMethodsYet, setNoPaymentMethodsYet] = useState<boolean>(
    isPrivatePage &&
      location.pathname !== '/register' &&
      location.pathname !== '/first-payment-method' &&
      location.pathname !== '/request-issuers' &&
      auth.account !== null &&
      auth.account?.business &&
      !auth.account?.business.hasCommerces,
  );
  const hasToken = cookies.get('token') !== undefined;
  const isSuperAdmin = auth.account?.type === UserTypeEnum.SUPERADMIN;
  const isBusinessAdmin = auth.account?.type === UserTypeEnum.OWNER;

  useEffect(() => {
    if (hasToken) {
      if (
        !auth.loggedIn &&
        !auth.loggingIn &&
        auth.logInErrorMessage === null &&
        (isPrivatePage || location.pathname.includes('first-payment-method'))
      ) {
        dispatch(getAccount());
      }

      if (location.pathname === '/login') {
        setIsPrivatePage(true);
        if (auth.account && auth.account.business === null && !isSuperAdmin) {
          navigate('/register?step=2');
        } else {
          setNoPaymentMethodsYet(
            auth.account !== null && auth.account?.business && !auth.account?.business.hasCommerces,
          );
          isSuperAdmin ? navigate('/payments') : navigate('/dashboard');
        }
      }
    }

    if (!hasToken && isPrivatePage) {
      navigate('/login');
      setIsPrivatePage(false);
    }
  }, [
    isPrivatePage,
    auth.loggedIn,
    auth.loggingIn,
    hasToken,
    auth.logInErrorMessage,
    auth.account,
    isSuperAdmin,
    location.pathname,
    navigate,
    dispatch,
  ]);

  useEffect(() => {
    const path = location.pathname;
    const isPrivate = urlHelper.isPrivatePage(path);

    setIsHome(path === '/');
    setIsPrivatePage(isPrivate);
    setNoPaymentMethodsYet(
      isPrivate &&
        path !== '/register' &&
        path !== '/first-payment-method' &&
        path !== '/request-issuers' &&
        auth.account !== null &&
        auth.account?.business &&
        !auth.account?.business.hasCommerces,
    );

    if (
      isPrivate &&
      path !== '/register' &&
      path !== '/request-issuers' &&
      path !== '/first-payment-method' &&
      path !== '/user-faqs' &&
      auth.account &&
      auth.account.business === null &&
      !isSuperAdmin
    ) {
      navigate('/register?step=2');
    }

    if (path === '/login' && hasToken) {
      isSuperAdmin ? navigate('/payments') : navigate('/dashboard');
    }
  }, [auth.account, hasToken, isSuperAdmin, location.pathname, navigate]);

  const isLoadingBusiness = hasToken && !auth.loggedIn && isPrivatePage && auth.account?.business === null;

  const hasPaymentMethods =
    auth.account !== null && auth.account?.business && auth.account?.business.hasCommerces;

  const isRedirectPage = location.pathname.startsWith('/redirect');
  const isRegisterPage = location.pathname.includes('register');
  const isIssuersPage = location.pathname.includes('request-issuers');
  const isFaqsPage = location.pathname.includes('user-faqs');
  const isPublicPageWithPaymentMethod = hasPaymentMethods && (isIssuersPage || isFaqsPage);

  return isMaintenanceMode ? (
    <Routes>
      <Route path="*" element={<Maintenance />} />
    </Routes>
  ) : (
    <div className="main">
      <ScrollToTop />
      {(isPrivatePage && !isRegisterPage) || isPublicPageWithPaymentMethod ? (
        <Header />
      ) : (
        !isRedirectPage && <PublicHeader isHome={isHome} />
      )}
      <div className={isHome ? 'home' : 'content'}>
        {isLoadingBusiness || auth.gettingAccount ? (
          <Loading />
        ) : noPaymentMethodsYet && !isSuperAdmin ? (
          navigate('/first-payment-method')
        ) : (
          <Routes>
            <Route
              path="/business"
              element={auth.account ? isBusinessAdmin ? <Business /> : <Forbidden /> : <Loading />}
            />
            <Route path="/confirm-reset" element={<ConfirmResetPassword />} />
            <Route path="/configuration" element={<Configuration />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/user-faqs" element={<FAQs />} />
            <Route path="/links" element={<Links linkType={LinkTypeEnum.PERMANENT} />} />
            <Route path="/permanent-link" element={<Link />} />
            <Route path="/one-use-link" element={<Link />} />
            <Route path="/on-the-fly-payments" element={<OnTheFlyPayments />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/login" element={<Login />} />
            <Route path="/one-use-links" element={<Links linkType={LinkTypeEnum.ONETIME} />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/plexo-payment" element={<PlexoPayment />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/redirect" element={<Redirect />} />
            <Route path="/register" element={<Register />} />
            <Route path="/first-payment-method" element={<FirstPaymentMethod />} />
            <Route path="/result" element={<Result />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/request-issuers" element={<RequestIssuers />} />
            <Route path="/user-contact" element={<Contact />} />
            <Route
              path="/manage-clients"
              element={auth.account ? isSuperAdmin ? <ManageClients /> : <Forbidden /> : <Loading />}
            />
            <Route
              path="/manage-users"
              element={auth.account ? isSuperAdmin ? <ManageClientUsers /> : <Forbidden /> : <Loading />}
            />
            <Route
              path="/verifications"
              element={auth.account ? isSuperAdmin ? <UnconfirmedUsers /> : <Forbidden /> : <Loading />}
            />
            <Route
              path="/users"
              element={auth.account !== null ? isBusinessAdmin ? <Users /> : <Forbidden /> : <Loading />}
            />
            <Route
              path="/split-payments"
              element={
                auth.account !== null ? isBusinessAdmin ? <SplitPayments /> : <Forbidden /> : <Loading />
              }
            />
            <Route
              path="/sub-business"
              element={
                auth.account !== null ? isBusinessAdmin ? <SubBusiness /> : <Forbidden /> : <Loading />
              }
            />
            <Route
              path="/user"
              element={
                auth.account ? isSuperAdmin || isBusinessAdmin ? <SubUser /> : <Forbidden /> : <Loading />
              }
            />
            <Route path="/payments/:paymentId" element={<PaymentDetail />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/verify" element={<ConfirmEmail />} />
            <Route path="/" element={<Home />} />
            <Route path="/:companyId?/:currency?/:amount?/:linkId?" element={<Landing />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default Main;
