import {
  ADD_PAYMENT_SUB_BUSINESS_SUCCESS,
  ADD_SUB_BUSINESS,
  CREATE_SUB_BUSINESS_FAILED,
  CREATE_SUB_BUSINESS_SUCCESS,
  CREATING_SUB_BUSINESS,
  FETCH_SUB_BUSINESS_FAILED,
  FETCHING_SUB_BUSINESS,
  LogoutActionTypes,
  SubBusinessDataTypes,
  UPDATE_SUB_BUSINESS_FAILED,
  UPDATE_SUB_BUSINESS_SUCCESS,
  UPDATING_SUB_BUSINESS,
} from '../config/ActionTypes';
import { SubBusiness, SubBusinessState } from '../config/types';

export const initialState: SubBusinessState = {
  loadingSubBusiness: false,
  subBusiness: null,
  subBusinessErrorMessage: null,
  creatingSubBusiness: false,
  createSubBusinessSuccess: false,
  createSubBusinessErrorMessage: null,
  updatingSubBusiness: false,
  updateSubBusinessSuccess: false,
  updateSubBusinessErrorMessage: null,
  deletingSubBusiness: false,
  deleteSubBusinessSuccess: false,
  deleteSubBusinessErrorMessage: null,
};

export function subBusinessReducer(
  state = initialState,
  action: SubBusinessDataTypes | LogoutActionTypes,
): SubBusinessState {
  switch (action.type) {
    case FETCHING_SUB_BUSINESS:
      return {
        ...state,
        loadingSubBusiness: true,
        subBusiness: null,
        subBusinessErrorMessage: null,
      };

    case ADD_SUB_BUSINESS:
      return {
        ...state,
        loadingSubBusiness: false,
        subBusiness: action.subBusiness,
        subBusinessErrorMessage: null,
      };

    case CREATING_SUB_BUSINESS:
      return {
        ...state,
        creatingSubBusiness: true,
        createSubBusinessSuccess: false,
        createSubBusinessErrorMessage: null,
      };

    case CREATE_SUB_BUSINESS_SUCCESS:
      const newSubBusiness = state.subBusiness ? [...state.subBusiness] : [];
      newSubBusiness.push({ ...action.subBusiness });

      return {
        ...state,
        subBusiness: newSubBusiness,
        creatingSubBusiness: false,
        createSubBusinessSuccess: true,
        createSubBusinessErrorMessage: null,
      };

    case CREATE_SUB_BUSINESS_FAILED:
      return {
        ...state,
        creatingSubBusiness: false,
        createSubBusinessSuccess: false,
        createSubBusinessErrorMessage: action.error,
      };

    case FETCH_SUB_BUSINESS_FAILED:
      return {
        ...state,
        loadingSubBusiness: false,
        subBusiness: null,
        subBusinessErrorMessage: action.error,
      };

    case UPDATING_SUB_BUSINESS:
      return {
        ...state,
        updatingSubBusiness: true,
        updateSubBusinessSuccess: false,
        updateSubBusinessErrorMessage: null,
      };

    case UPDATE_SUB_BUSINESS_SUCCESS:
      let updatedSubBusiness = state.subBusiness!.map((s) => {
        if (s.id === action.subBusiness.id) {
          let returnedSubBusiness: SubBusiness = {
            ...s,
            name: action.subBusiness.name,
            status: action.subBusiness.status,
          };

          return returnedSubBusiness;
        }

        return s;
      });

      return {
        ...state,
        updatingSubBusiness: false,
        updateSubBusinessSuccess: true,
        subBusiness: updatedSubBusiness,
        updateSubBusinessErrorMessage: null,
      };

    case ADD_PAYMENT_SUB_BUSINESS_SUCCESS:
      return {
        ...state,
        updatingSubBusiness: false,
        updateSubBusinessSuccess: true,
        updateSubBusinessErrorMessage: null,
      };

    case UPDATE_SUB_BUSINESS_FAILED:
      return {
        ...state,
        updatingSubBusiness: false,
        updateSubBusinessSuccess: false,
        updateSubBusinessErrorMessage: action.error,
      };

    default:
      return state;
  }
}
