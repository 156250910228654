import { CircularProgress, createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../assets/deleteIcon';
import { fetchPaymentMethods } from '../../store/action_creators/paymentMethods.actions';
import { deleteSubBusinessPaymentMethod } from '../../store/action_creators/subBusiness.actions';
import { IssuersCommand, PaymentMethod, RootState, SubBusiness } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { AddPaymentMethodIcon } from '../Icons/AddPaymentMethodIcon';
import SinglePaymentMethodDeleteTooltip from './SingleDeletePaymentMethodDeleteTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commerceCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      backgroundColor: styles.white,
      padding: '0.8125rem 1rem',
    },
    grayscale: {
      filter: 'grayscale(100%)',
    },
    icon: {
      alignSelf: 'flex-end',
      cursor: 'pointer',
    },
    divider: {
      width: '85%',
      margin: '0.625rem 0',
    },
  }),
);

interface PaymentMethodsToAddProps {
  openModalWithCommerceData: (paymentMethod: PaymentMethod) => void;
  paymentMethodsToAdd: IssuersCommand[];
  addPaymentMethodToSubBusiness: (paymentMethod: PaymentMethod, isAlreadyInState: boolean) => void;
  selectedSubBusiness: SubBusiness;
}

function PaymentMethodsToAdd({
  openModalWithCommerceData,
  paymentMethodsToAdd,
  addPaymentMethodToSubBusiness,
  selectedSubBusiness,
}: PaymentMethodsToAddProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { auth, paymentMethods } = useSelector((state: RootState) => state);
  const businessId = auth.account?.business.id;

  useEffect(() => {
    if (
      auth.account &&
      !paymentMethods.loadingPaymentMethods &&
      paymentMethods.paymentMethods === null &&
      paymentMethods.paymentMethodsErrorMessage === null
    ) {
      dispatch(fetchPaymentMethods(auth.account.business.id!));
    }
  }, [
    auth.account,
    paymentMethods.loadingPaymentMethods,
    paymentMethods.paymentMethods,
    paymentMethods.paymentMethodsErrorMessage,
    dispatch,
  ]);

  if (paymentMethods.loadingPaymentMethods) return <CircularProgress />;

  const deletePaymentMethodFromSubBusiness = (
    paymentMethod: PaymentMethod,
    comerceToRemove: PaymentMethod,
    isPaymentMethodInSubBusiness: boolean,
  ) => {
    dispatch(deleteSubBusinessPaymentMethod(businessId!, paymentMethod.issuerId, selectedSubBusiness.id));
    addPaymentMethodToSubBusiness(comerceToRemove, isPaymentMethodInSubBusiness);
  };

  const handleClick = (paymentMethod: PaymentMethod, isPaymentMethodInSubBusiness: boolean) => {
    if (!(selectedSubBusiness && isPaymentMethodInSubBusiness && paymentMethodsToAdd.length === 1)) {
      const comerceToRemove = { ...paymentMethod, issuer: paymentMethod.issuerId };

      isPaymentMethodInSubBusiness
        ? selectedSubBusiness
          ? deletePaymentMethodFromSubBusiness(paymentMethod, comerceToRemove, isPaymentMethodInSubBusiness)
          : addPaymentMethodToSubBusiness(comerceToRemove, isPaymentMethodInSubBusiness)
        : openModalWithCommerceData(paymentMethod);
    }
  };

  return (
    <>
      <div className="payment-methods-list">
        {paymentMethods.paymentMethods &&
          paymentMethods.paymentMethods.map((paymentMethod, index) => {
            const isPaymentMethodInSubBusiness = paymentMethodsToAdd.some(
              (issuerCommand: IssuersCommand) => +issuerCommand.issuerId === paymentMethod.issuerId,
            );
            return (
              <div
                className={`${classes.commerceCard} ${isPaymentMethodInSubBusiness ? '' : classes.grayscale}`}
                key={index}
              >
                <div className={classes.icon}>
                  <span onClick={() => handleClick(paymentMethod, isPaymentMethodInSubBusiness)}>
                    {selectedSubBusiness &&
                    isPaymentMethodInSubBusiness &&
                    paymentMethodsToAdd.length === 1 ? (
                      <SinglePaymentMethodDeleteTooltip />
                    ) : isPaymentMethodInSubBusiness ? (
                      <DeleteIcon />
                    ) : (
                      <AddPaymentMethodIcon />
                    )}
                  </span>
                </div>
                <img src={paymentMethod.issuerImageUrl} alt="logo" />
                <Divider orientation="horizontal" className={classes.divider} />
                <div className="number">
                  <Typography noWrap className="payment-method-text">
                    {paymentMethod.issuerName ? paymentMethod.issuerName : '-'}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default PaymentMethodsToAdd;
