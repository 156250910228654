import { Paper, useMediaQuery } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import schemas from '../../../data/schemas';
import { dateHelper } from '../../../helpers/dateHelper';
import { createLink, updateLink } from '../../../store/action_creators/links.actions';
import { Currency, LinkTypeEnum, PaymentTypeEnum, ValidUntil } from '../../../store/config/enums';
import {
  AuthState,
  CreateSplitLinkRequest,
  Link,
  PaymentMethod,
  RootState,
  SplitLinkRequestValues,
  UpdateSplitLinkRequest,
  UpdateSplitLInkRequestValues,
} from '../../../store/config/types';
import { theme } from '../../../styles/theme';
import Inputs from '../Inputs';
import LinkActions from '../LinkActions';
import PaymentMethods from './PaymentMethods';
import SplitLinkPaymentInputs from './SubBusinessInputs.tsx/SplitLinkPaymentInputs';
import UpdatePaymentMethodsOptions from './UpdatePaymentMethodsOptions';

interface SplitLinkProps {
  clickedCopy: boolean | null;
  setClickedCopy: React.Dispatch<React.SetStateAction<boolean | null>>;
  creatingLink: boolean;
  setCreatingLink: React.Dispatch<React.SetStateAction<boolean>>;
  updatingLink: boolean;
  setUpdatingLink: React.Dispatch<React.SetStateAction<boolean>>;
  paymentMethodsIds: number[];
  setPaymentMethodsIds: React.Dispatch<React.SetStateAction<number[]>>;
  commonClasses: Record<string, string>;
}

function SplitLink({
  clickedCopy,
  setClickedCopy,
  creatingLink,
  setCreatingLink,
  updatingLink,
  setUpdatingLink,
  paymentMethodsIds,
  setPaymentMethodsIds,
  commonClasses,
}: SplitLinkProps) {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const linkType = location.pathname.includes('one-use') ? LinkTypeEnum.ONETIME : LinkTypeEnum.PERMANENT;
  const link = (location.state?.link as Link) ?? null;
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const subBusinesses = useSelector((state: RootState) => state.business.subBusinesses);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const allInstallmentIds = useMemo(
    () => paymentMethods.map((paymentMethod) => paymentMethod.id),
    [paymentMethods],
  );

  const initialValues: SplitLinkRequestValues = {
    businessId: auth.account?.business.id ? auth.account?.business.id.toString() : '',
    currency: link ? link.amount?.currency : Currency.PESO,
    amount: link ? link.amount.value : 0,
    vatRate: link ? link.vatRate : 0,
    description: link ? link.description : '',
    reference: link ? link.reference : '',
    validUntil: link && link.validUntil !== null ? ValidUntil.CUSTOM : ValidUntil.NONE,
    validUntilDate: link && link.validUntil ? new Date(link.validUntil) : new Date(),
    linkType: linkType,
    installmentsIds: link ? link.commerceIds : [],
    subBusinessId: link ? link.splitLinkDto?.subBusinessId || '' : '',
    subBusinessAmount: link ? link.splitLinkDto?.amount.value || 0 : 0,
    subBusinessVatRate: link ? link.splitLinkDto?.vatRate || 0 : 0,
  };

  useEffect(() => {
    if (subBusinesses && subBusinesses.length > 0 && initialValues.subBusinessId) {
      const selectedSubBusiness = subBusinesses.find((sb) => sb.id === initialValues.subBusinessId);
      if (selectedSubBusiness) {
        setPaymentMethods(selectedSubBusiness.installmentsDto);
      }
    } else {
      setPaymentMethods([]);
    }
  }, [subBusinesses, initialValues.subBusinessId]);

  const sendLinkRequest = (values: SplitLinkRequestValues) => {
    if (link && link.splitLinkDto) {
      const updateSplitLinkRequest: UpdateSplitLInkRequestValues = {
        splitLinkId: link.splitLinkDto.id,
        amount: +values.subBusinessAmount,
        vatRate: values.subBusinessVatRate,
      };

      setUpdatingLink(true);
      const request: UpdateSplitLinkRequest = {
        linkType: link.linkType,
        description: values.description !== undefined ? values.description : '',
        amount: { value: +values.amount, currency: values.currency },
        status: link.status,
        validUntil: dateHelper.getDateFromEnum(values.validUntil, values.validUntilDate),
        installmentsIds: paymentMethodsIds,
        reference: values.reference || '',
        currency: values.currency,
        vatRate: values.vatRate,
        updateSplitLinkRequest: updateSplitLinkRequest,
        paymentType: PaymentTypeEnum.Split,
      };

      if (linkType === LinkTypeEnum.ONETIME) request.reference = values.reference;
      dispatch(updateLink(request, link.id, auth.account!.business.id!.toString()));
    } else {
      setCreatingLink(true);
      const request: CreateSplitLinkRequest = {
        businessId: auth.account?.business.id !== undefined ? auth.account?.business.id! : 0,
        amount: { value: +values.amount, currency: values.currency },
        vatRate: values.vatRate,
        linkType: linkType ? linkType : LinkTypeEnum.PERMANENT,
        userId: auth.account?.userId!,
        description: values.description !== undefined ? values.description : '',
        reference: values.reference || '',
        validUntil: dateHelper.getDateFromEnum(values.validUntil, values.validUntilDate),
        installmentsIds: paymentMethodsIds,
        splitLinkRequest: {
          amount: +values.subBusinessAmount,
          subBusinessId: values.subBusinessId,
          vatRate: values.subBusinessVatRate,
        },
        paymentType: PaymentTypeEnum.Split,
      };
      if (linkType === LinkTypeEnum.ONETIME) request.reference = values.reference;
      dispatch(createLink(request));
    }
  };

  const submitLink = (values: SplitLinkRequestValues, actions: FormikHelpers<SplitLinkRequestValues>) => {
    clickedCopy ? setClickedCopy(true) : setClickedCopy(false);

    if (paymentMethodsIds.length === 0) {
      actions.setSubmitting(false);
    } else {
      sendLinkRequest(values);
    }
  };

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0 && !link) {
      setPaymentMethodsIds(paymentMethods.map((paymentMethod) => paymentMethod.id));
    }
  }, [paymentMethods, setPaymentMethodsIds, link]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schemas.SplitLinkSchema}
        onSubmit={(values, actions) => submitLink(values, actions)}
      >
        {({ values }) => {
          return (
            <div className={commonClasses.container}>
              <UpdatePaymentMethodsOptions setPaymentMethods={setPaymentMethods} />
              <Form id="new-link-form">
                <Paper className={commonClasses.paperRoot}>
                  <div className={commonClasses.titleContainer}>
                    <p className={commonClasses.contentTitle}>Información general</p>
                  </div>
                  <Inputs values={values} linkType={linkType} paymentType={PaymentTypeEnum.Split} />
                </Paper>
                <Paper className={commonClasses.paperRoot}>
                  <div className={commonClasses.paymentHeader}>
                    <p className={commonClasses.contentTitleHeader}>Información de pago</p>
                  </div>
                  <SplitLinkPaymentInputs
                    linkType={linkType}
                    paymentType={PaymentTypeEnum.Split}
                    link={link}
                  />
                </Paper>
                <PaymentMethods
                  paymentMethods={paymentMethods}
                  paymentMethodsIds={paymentMethodsIds}
                  setPaymentMethodsIds={setPaymentMethodsIds}
                  allInstallmentIds={allInstallmentIds}
                  classes={commonClasses}
                  subBusinessId={values.subBusinessId}
                />
                {isMobile && (
                  <LinkActions
                    setClickedCopy={setClickedCopy}
                    link={link}
                    creatingLink={creatingLink}
                    updatingLink={updatingLink}
                    clickedCopy={clickedCopy}
                  />
                )}
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
}

export default SplitLink;
