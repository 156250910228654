import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setTimeout } from 'timers';
import { uuid } from 'uuidv4';
import { ErrorCard } from '../../components/ErrorCard';
import ExpiredLinkCard from '../../components/ExpiredLinkCard/ExpiredLinkCard';
import { AlertTriangle } from '../../components/Icons/AlertTriangle';
import { ConsumerInstallments } from '../../components/LandingComponents/ConsumerInstallments';
import { OnTheFlyLanding } from '../../components/LandingComponents/OnTheFlyLanding';
import { PermanentLinkLanding } from '../../components/LandingComponents/PermanentLinkLanding';
import { Loading } from '../../components/Loading';
import { PaidLinkCard } from '../../components/PaidLinkCard';
import { timeToValidateLinks } from '../../data/values';
import { currencyHelper } from '../../helpers/currencyHelper';
import { linkHelper } from '../../helpers/linkHelper';
import { getBusiness } from '../../store/action_creators/business.actions';
import { getLinkData, validateLink } from '../../store/action_creators/links.actions';
import { fetchBusinessIssuers } from '../../store/action_creators/paymentMethods.actions';
import { beginPayment } from '../../store/action_creators/payments.actions';
import { LinkTypeEnum, PaymentStatusEnum } from '../../store/config/enums';
import {
  Amount,
  BeginPaymentRequest,
  BusinessIssuer,
  LandingParams,
  LinkDataPayment,
  Payment,
  RootState,
} from '../../store/config/types';
import { ValidatePayment } from '../ValidatePayment';

interface InstallmentValues {
  installments: number;
  comment: string;
}

function Landing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params: LandingParams = useParams();
  const { business, links, payments, paymentMethods } = useSelector((state: RootState) => state);
  const isLink = params.companyId && params.currency && params.amount && params.linkId;
  const isOnTheFly = params.companyId && params.linkId === undefined;
  const paymentUrl = location.pathname;
  const errorTitle = 'Ocurrió un error al validar el link ingresado';

  const [malformed, setMalformed] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<string | null>(null);
  const [step, setStep] = useState<number>(1);
  const [selectedIssuer, setSelectedIssuer] = useState<BusinessIssuer | null>(null);
  const [amountToPay, setAmountToPay] = useState<Amount | null>(null);
  const [validatingPayment, setValidatingPayment] = useState<boolean>(true);
  const [gettingLinkData, setGettingLinkData] = useState<boolean>(true);

  const gettingLinkDataOrValidatingLink: boolean =
    links.gettingLinkData || links.validatingLink || gettingLinkData || validatingPayment;
  const expiredLink =
    links.linkData &&
    !!links.linkData.link.validUntil &&
    new Date(links.linkData.link.validUntil) < new Date();
  const oneTimeLink =
    links.linkData?.link.linkType === LinkTypeEnum.ONETIME && !(links.gettingLinkData || gettingLinkData);
  const oneTimeLinkWithPayments =
    oneTimeLink &&
    links.linkData?.payments &&
    links.linkData?.payments.length > 0 &&
    !gettingLinkDataOrValidatingLink;
  const expiredOneTimeLink = expiredLink && oneTimeLink && !gettingLinkDataOrValidatingLink;
  const expiredOneTimeLinkWithPaymentCreated =
    expiredOneTimeLink &&
    links.linkData?.payments &&
    links.linkData?.payments.length > 0 &&
    !links.linkData?.payments.find(
      (payment) =>
        payment.status === PaymentStatusEnum.APPROVED || payment.status === PaymentStatusEnum.CANCELLED,
    ) &&
    links.linkData?.payments.find((payment) => payment.status === PaymentStatusEnum.CREATED);

  const linkWithoutIssuers =
    isLink &&
    links.linkData &&
    links.linkData.link.installmentsDto &&
    links.linkData.link.installmentsDto.length === 0;

  const linkToValidate: boolean =
    !!params.linkId &&
    (!expiredLink || (expiredLink && !!expiredOneTimeLinkWithPaymentCreated)) &&
    (!oneTimeLink ||
      (oneTimeLink &&
        ((!!links.linkData?.payments && !(links.linkData?.payments.length > 0)) ||
          (!!links.linkData?.payments &&
            links.linkData?.payments.length > 0 &&
            !!!links.linkData?.payments.find(
              (payment) =>
                payment.status === PaymentStatusEnum.APPROVED ||
                payment.status === PaymentStatusEnum.CANCELLED,
            ))))) &&
    !gettingLinkData &&
    !links.gettingLinkData &&
    !links.validatingLink &&
    links.linkToPay === null &&
    links.validateLinkErrorMessage === null;

  useEffect(() => {
    if (
      isLink &&
      !links.gettingLinkData &&
      links.linkData === null &&
      links.getLinkDataErrorMessage === null
    ) {
      dispatch(getLinkData(params.linkId!));
    }

    if (
      !business.gettingBusiness &&
      business.business === null &&
      business.getBusinessErrorMessage === null
    ) {
      dispatch(getBusiness(params.companyId!));
    }

    if (!params.companyId) {
      setMalformed(true);
    }
  }, [
    params,
    links.validatingLink,
    links.linkToPay,
    links.validateLinkErrorMessage,
    links.gettingLinkData,
    links.getLinkDataErrorMessage,
    links.linkData,
    business.gettingBusiness,
    business.business,
    business.getBusinessErrorMessage,
    isLink,
    isOnTheFly,
    dispatch,
  ]);

  useEffect(() => {
    if (payments.plexoUrl) {
      navigate('/plexo-payment');
    }
  }, [payments.plexoUrl, navigate]);

  useEffect(() => {
    if (
      !paymentMethods.loadingBusinessIssuers &&
      paymentMethods.businessIssuers === null &&
      paymentMethods.businessIssuersErrorMessage === null &&
      business.business?.id
    ) {
      dispatch(fetchBusinessIssuers(business.business!.id));
    }
  }, [
    paymentMethods.loadingBusinessIssuers,
    paymentMethods.businessIssuers,
    paymentMethods.businessIssuersErrorMessage,
    business.business,
    dispatch,
  ]);

  useEffect(() => {
    if (!links.validatingLink && links.linkToPay !== null) {
      const amount = {
        currency: links.linkToPay.amount.currency,
        value: linkHelper.getTotalLinkAmount(links.linkToPay),
      };
      setAmountToPay(amount);
    }
  }, [links.validatingLink, links.linkToPay, setAmountToPay]);

  useEffect(() => {
    if (warnings === null && links.validateLinkSuccess && links.linkToPay) {
      const totalAmountFetched = linkHelper.getTotalLinkAmount(links.linkToPay);
      const formattedAmount = Math.trunc(totalAmountFetched).toString();
      const businessFetched = links.linkToPay.businessUrlName;

      if (formattedAmount !== params.amount) {
        setWarnings('El importe que hemos encontrado difiere del importe que está ingresado en la URL.');
      }

      if (businessFetched !== params.companyId) {
        setWarnings('La empresa que hemos encontrado difiere de la que está ingresada en la URL.');
      }

      if (formattedAmount !== params.amount && businessFetched !== params.companyId) {
        setWarnings(
          'Los datos de importe y empresa que hemos encontrado difieren de los que están ingresados en la URL.',
        );
      }

      if (formattedAmount === params.amount && businessFetched === params.companyId) {
        setWarnings('');
      }
    }

    if (params.currency) {
      params.currency = currencyHelper.formatCurrencyToNumber(params.currency);
    }

    if (warnings === null && params.currency && !currencyHelper.isValidCurrency(Number(params.currency))) {
      setWarnings('La moneda que está ingresada en la URL no existe en el sistema.');
    }
  }, [
    params,
    warnings,
    setWarnings,
    links.validateLinkSuccess,
    links.linkToPay,
    params.currency,
    params.amount,
    params.companyId,
    linkWithoutIssuers,
  ]);

  const isTest =
    window.location.host === 'localhost:3000' ||
    window.location.host === 'px-links.netlify.app' ||
    window.location.host === 'plinks.handsoft.com.uy';
  const environmentId = isTest ? '1snn5n9w' : 'k8vif92e';
  const paymentMethodId = isTest ? '123' : 'visanetuy_px_2048256716';
  const fingerprintId = uuid();
  const visaFingerprint = paymentMethodId + fingerprintId;
  let fingerprint = isTest ? 'd1cab7e203194d09972b7fb3d4657fc8' : fingerprintId;

  const loadVisaDeviceFingerprint = (callback: any) => {
    const existingScript = document.getElementById('visaDeviceFingerprint');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${environmentId}&session_id=${visaFingerprint}`;
      script.id = 'visaDeviceFingerprint';
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  useEffect(() => {
    loadVisaDeviceFingerprint(() => console.log('fingerprint loaded'));
  });

  const submitPayment = (values: InstallmentValues) => {
    if (links.linkToPay) {
      const linkToPayRequest: BeginPaymentRequest = {
        linkId: links.linkToPay.id,
        businessUrlName: links.linkToPay.businessName,
        currency: links.linkToPay.amount.currency,
        value: linkHelper.getTotalLinkAmount(links.linkToPay),
        redirectUrl: `${window.location.origin}/redirect`,
        selectedInstallment: values.installments,
        issuerPlexoId: selectedIssuer!.issuerId,
        deviceFingerprint: fingerprint,
        clientComment: values.comment,
        paymentUrl,
      };
      dispatch(beginPayment(linkToPayRequest));
    } else if (amountToPay && business.business?.acceptsOnTheFly) {
      const onTheFlyRequest: BeginPaymentRequest = {
        businessUrlName: params.companyId!,
        currency: amountToPay.currency,
        value: amountToPay.value,
        redirectUrl: `${window.location.origin}/redirect`,
        selectedInstallment: values.installments,
        issuerPlexoId: selectedIssuer!.issuerId,
        deviceFingerprint: fingerprint,
        clientComment: values.comment,
        paymentUrl,
      };
      dispatch(beginPayment(onTheFlyRequest));
    }
  };

  useEffect(() => {
    if (linkToValidate) {
      dispatch(validateLink(params.linkId!));
    }
  }, [linkToValidate, params.linkId, dispatch]);

  function renderPaidLinkCard(
    payment: LinkDataPayment | Payment,
    businessId: number,
    businessVatRate: number,
  ) {
    if (payment.status === PaymentStatusEnum.APPROVED) {
      return <PaidLinkCard payment={payment} businessId={businessId} businessVatRate={businessVatRate} />;
    }
    if (payment.status === PaymentStatusEnum.CANCELLED) {
      return <PaidLinkCard />;
    }
    return null;
  }

  // Workaround to show ValidatePayment for at least 3 seconds
  useEffect(() => {
    setTimeout(() => {
      if (!links.gettingLinkData) {
        setGettingLinkData(false);
      }
      if (!links.validatingLink) {
        setValidatingPayment(false);
      }
    }, timeToValidateLinks);
  }, [linkToValidate, links.gettingLinkData, links.validatingLink, params.linkId, dispatch]);

  if (
    ((expiredLink && !oneTimeLink) ||
      (expiredLink && !!expiredOneTimeLinkWithPaymentCreated && links.validateLinkErrorMessage) ||
      (expiredLink && links.linkData?.payments && !!!links.linkData?.payments.length) ||
      (expiredLink &&
        !!links.linkData?.payments &&
        links.linkData?.payments.length > 0 &&
        !links.linkData?.payments.find(
          (payment) =>
            payment.status === PaymentStatusEnum.APPROVED || payment.status === PaymentStatusEnum.CANCELLED,
        ) &&
        !!links.linkToPay?.payments &&
        links.linkToPay.payments.length > 0 &&
        !links.linkToPay.payments.find(
          (payment) =>
            payment.status === PaymentStatusEnum.APPROVED || payment.status === PaymentStatusEnum.CANCELLED,
        ))) &&
    business.business &&
    !validatingPayment &&
    !links.validatingLink
  ) {
    return <ExpiredLinkCard link={links.linkData!} business={business.business} />;
  }

  if (oneTimeLinkWithPayments) {
    const payment = links.linkData?.payments.find(
      (payment) =>
        payment.status === PaymentStatusEnum.APPROVED || payment.status === PaymentStatusEnum.CANCELLED,
    );
    if (payment) {
      if (payment.status === PaymentStatusEnum.CANCELLED || payment.status === PaymentStatusEnum.APPROVED) {
        return renderPaidLinkCard(payment, business.business!.id!, business.business!.vatRate!);
      }
    }
  }

  if (
    !!links.linkToPay?.payments &&
    links.linkToPay.payments.length > 0 &&
    !gettingLinkDataOrValidatingLink
  ) {
    const payment = links.linkToPay.payments.find(
      (payment) =>
        payment.status === PaymentStatusEnum.APPROVED || payment.status === PaymentStatusEnum.CANCELLED,
    );
    if (payment) {
      return renderPaidLinkCard(payment, business.business!.id!, business.business!.vatRate!);
    }
  }

  if (
    (links.getLinkDataErrorMessage || links.validateLinkErrorMessage) &&
    !expiredLink &&
    !gettingLinkDataOrValidatingLink
  ) {
    return (
      <ErrorCard
        title={errorTitle}
        subtitle="Por favor Intenta de nuevo en unos segundos Si deseas salir solo debes cerrar la ventana."
      />
    );
  } else if (linkWithoutIssuers) {
    return (
      <ErrorCard title={errorTitle} subtitle="El link seleccionado no tiene medios de pago habilitados" />
    );
  } else if (business.getBusinessErrorMessage) {
    return <ErrorCard title={errorTitle} subtitle="No pudimos encontrar la empresa a la que deseas pagar" />;
  } else if (isOnTheFly && business.business && !business.business.acceptsOnTheFly) {
    return (
      <ErrorCard title={errorTitle} subtitle="La empresa a la que deseas pagar no acepta pagos rápidos" />
    );
  } else if (malformed) {
    return <ErrorCard title={errorTitle} subtitle="La URL ingresada no tiene el formato correcto" />;
  } else if (step === 1) {
    return (
      <>
        {warnings && (
          <div className="consumer-warnings">
            <AlertTriangle />
            <div className="warning-texts">
              <Typography variant="subtitle2">{`${warnings} Antes de efectivizar el pago, revisa que la información del pago que se muestra es la deseada`}</Typography>
            </div>
          </div>
        )}
        {gettingLinkDataOrValidatingLink ? (
          <div className="screen-container landing">
            <ValidatePayment />
          </div>
        ) : (
          <div>
            {links.linkToPay ? (
              <PermanentLinkLanding
                linkToPay={links.linkToPay}
                business={business?.business}
                step={step}
                setStep={setStep}
                selectedIssuer={selectedIssuer}
                setSelectedIssuer={setSelectedIssuer}
              />
            ) : business.business ? (
              <OnTheFlyLanding
                params={params}
                businessIssuers={paymentMethods.businessIssuers}
                selectedIssuer={selectedIssuer}
                setSelectedIssuer={setSelectedIssuer}
                business={business.business}
                beginningPayment={payments.beginningPayment}
                setAmount={setAmountToPay}
                selectedAmount={amountToPay}
                setStep={setStep}
                step={step}
              />
            ) : (
              <Loading />
            )}
          </div>
        )}
      </>
    );
  } else if (step === 2) {
    return (
      <ConsumerInstallments
        submit={submitPayment}
        issuer={selectedIssuer!}
        beginningPayment={payments.beginningPayment}
        linkToPay={links.linkToPay!}
        business={business?.business}
        step={step}
        setStep={setStep}
        params={params}
        selectedAmount={amountToPay}
      />
    );
  } else {
    return <Loading />;
  }
}

export default Landing;
