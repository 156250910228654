import { Button, Card, CircularProgress, Divider, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import SubBusinessDialog from '../../components/dialogs/PaymentMethodDialog/SubBusinessDialog';
import { FormTextField } from '../../components/forms/FormTextField';
import PaymentMethodsToAdd from '../../components/PaymentMethods/PaymentMethodsToAdd';
import { ScreenTitle } from '../../components/ScreenTitle';
import { createSubBusiness, updateSubBusiness } from '../../store/action_creators/subBusiness.actions';
import { Status, SubBusinessStatusEnum } from '../../store/config/enums';
import {
  CommerceToAdd,
  CreateSubBusinessRequest,
  IssuersCommand,
  PaymentMethod,
  RootState,
  SubBusiness as SubBusinessType,
  UpdateSubBusinessRequest,
} from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameCard: {
      margin: '1.75rem 0',
      padding: '1.25rem',
      '& p': {
        margin: 0,
        color: styles.iconColor,
        fontSize: '0.875rem',
        fontWeight: +styles.normalFontWeight,
      },
    },
    informationCol: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      '& p': {
        color: styles.slateBlue,
        margin: '0.8rem 0 -0.6rem 0 ',
      },
    },
    fieldAndButton: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '1rem',
      '& div:first-child': {
        margin: 0,
      },
      '& button': {
        marginLeft: '1rem',
        width: '12rem',
        height: '3.6rem',
      },
    },
    backButton: {
      minWidth: 'unset',
    },
  }),
);

interface SubBusinessValues {
  name: string;
}

function SubBusiness() {
  const classes = useStyles();
  const { auth, subBusiness } = useSelector((state: RootState) => state);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedSubBusiness: SubBusinessType = location.state?.subBusiness || null;
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
  const [creatingSubBusiness, setCreatingSubBusiness] = useState<boolean>(false);
  const [updatingSubBusiness, setUpdatingSubBusiness] = useState<boolean>(false);
  const [changingSubBusinessStatus, setChangingSubBusinessStatus] = useState<boolean>(false);
  const [paymentMethodsToAdd, setPaymentMethodsToAdd] = useState<IssuersCommand[]>([]);
  const businessId = auth.account?.business.id;
  const subBusinessEnabled = selectedSubBusiness?.status === SubBusinessStatusEnum.ACTIVE;

  useEffect(() => {
    if (selectedSubBusiness) {
      const commercesOfSubBusiness = selectedSubBusiness.installmentsDto.map((paymentMethod) => {
        return {
          issuerId: paymentMethod.issuerId.toString(),
          installments: paymentMethod.values,
        };
      });
      setPaymentMethodsToAdd((prevPaymentMethodsToAdd) => [
        ...prevPaymentMethodsToAdd,
        ...commercesOfSubBusiness,
      ]);
    }
  }, [selectedSubBusiness]);

  const openModalWithCommerceData = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setOpen(true);
  };

  const submitSubBusiness = (values: SubBusinessValues) => {
    if (auth.account) {
      const configureIssuersCommand = paymentMethodsToAdd.map((commerce: IssuersCommand) => {
        return {
          softDescriptor: commerce.softDescriptor ?? '',
          commerceNumber: commerce.commerceNumber ?? '',
          terminalNumber: commerce.terminalNumber ?? '',
          issuerId: commerce.issuerId,
          installments: commerce.installments,
          acquirer: commerce.acquirer,
        };
      });

      if (!selectedSubBusiness) {
        setCreatingSubBusiness(true);

        const createSubBusinessRequest: CreateSubBusinessRequest = {
          name: values.name,
          businessId: businessId!,
          configureIssuersCommand,
        };

        dispatch(createSubBusiness(createSubBusinessRequest));
      } else {
        setUpdatingSubBusiness(true);

        const updateSubBusinessRequest: UpdateSubBusinessRequest = {
          subBusinessId: selectedSubBusiness.id,
          name: values.name,
        };

        dispatch(updateSubBusiness(updateSubBusinessRequest));
      }
    }
  };

  const addPaymentMethodToSubBusiness = (commerce: CommerceToAdd, isAlreadyInState: boolean) => {
    const issuerCommand: IssuersCommand = {
      issuerId: commerce.issuer,
      softDescriptor: commerce.softDescriptor,
      commerceNumber: commerce.commerceNumber,
      terminalNumber: commerce.terminalNumber,
      installments: commerce.installments,
      acquirer: commerce.acquirer,
    };

    if (isAlreadyInState) {
      setPaymentMethodsToAdd(
        paymentMethodsToAdd.filter((item: IssuersCommand) => +item.issuerId !== +issuerCommand.issuerId),
      );
    } else {
      setPaymentMethodsToAdd([...paymentMethodsToAdd, issuerCommand]);
    }
    setOpen(false);
  };

  const changeSubBusinessStatus = () => {
    setChangingSubBusinessStatus(true);

    const updateSubBusinessRequest: UpdateSubBusinessRequest = {
      subBusinessId: selectedSubBusiness.id,
      name: selectedSubBusiness.name,
      status: subBusinessEnabled ? SubBusinessStatusEnum.INACTIVE : SubBusinessStatusEnum.ACTIVE,
    };

    dispatch(updateSubBusiness(updateSubBusinessRequest));
  };

  const closeSnack = () => {
    setCreatingSubBusiness(false);
    setUpdatingSubBusiness(false);
    setChangingSubBusinessStatus(false);
    navigate(-1);
  };

  return (
    <Formik
      initialValues={{
        name: selectedSubBusiness?.name || '',
      }}
      onSubmit={submitSubBusiness}
    >
      {({ values }) => {
        return (
          <div className="screen-container">
            <ScreenTitle
              title={`Split payments/${selectedSubBusiness ? selectedSubBusiness.name : 'Nuevo comercio'}`}
            >
              {selectedSubBusiness ? (
                <>
                  <Button
                    onClick={changeSubBusinessStatus}
                    variant="outlined"
                    color="secondary"
                    disabled={changingSubBusinessStatus}
                  >
                    {changingSubBusinessStatus ? (
                      <CircularProgress color="inherit" size={28} />
                    ) : subBusinessEnabled ? (
                      'Deshabilitar'
                    ) : (
                      'Habilitar'
                    )}
                  </Button>
                  <Divider orientation="vertical" flexItem />
                  <Button
                    className={classes.backButton}
                    onClick={() => navigate(-1)}
                    variant="outlined"
                    color="secondary"
                  >
                    Volver
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={classes.backButton}
                    onClick={() => navigate(-1)}
                    variant="outlined"
                    color="secondary"
                  >
                    Volver
                  </Button>
                  <Button
                    type="submit"
                    form="sub-business"
                    color="primary"
                    variant="contained"
                    disabled={
                      creatingSubBusiness ||
                      updatingSubBusiness ||
                      paymentMethodsToAdd.length === 0 ||
                      !values.name
                    }
                  >
                    {creatingSubBusiness || updatingSubBusiness ? (
                      <CircularProgress color="inherit" size={28} />
                    ) : (
                      'Crear comercio'
                    )}
                  </Button>
                </>
              )}
            </ScreenTitle>
            <Form id="sub-business" className="form">
              <Card className={classes.nameCard}>
                <p>
                  Ingresa los datos del comercio que deseas configurar. Para habilitarlo y asociarle links,
                  asegúrate de configurar al menos un medio de pago
                </p>
                <div className={classes.informationCol}>
                  <p>Nombre del comercio</p>
                  <div className={classes.fieldAndButton}>
                    <Field name="name" component={FormTextField} type="text" />
                    {selectedSubBusiness && (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        form="sub-business"
                        disabled={updatingSubBusiness || creatingSubBusiness}
                      >
                        {updatingSubBusiness ? <CircularProgress color="inherit" /> : 'Guardar cambios'}
                      </Button>
                    )}
                  </div>
                </div>
              </Card>
            </Form>
            <ScreenTitle title="Medios de Pago" />
            <PaymentMethodsToAdd
              selectedSubBusiness={selectedSubBusiness}
              openModalWithCommerceData={openModalWithCommerceData}
              paymentMethodsToAdd={paymentMethodsToAdd}
              addPaymentMethodToSubBusiness={addPaymentMethodToSubBusiness}
            />
            <SubBusinessDialog
              selectedSubBusiness={selectedSubBusiness}
              open={open}
              setOpen={setOpen}
              paymentMethod={selectedPaymentMethod}
              addPaymentMethodToSubBusiness={addPaymentMethodToSubBusiness}
            />
            <CustomSnackbar
              open={
                (updatingSubBusiness || changingSubBusinessStatus) &&
                (subBusiness.updateSubBusinessSuccess || subBusiness.updateSubBusinessErrorMessage !== null)
              }
              message={
                subBusiness.updateSubBusinessSuccess
                  ? 'Comercio actualizado correctamente'
                  : 'Ocurrió un error al actualizar el comercio'
              }
              handleClose={closeSnack}
              type={subBusiness.updateSubBusinessSuccess ? Status.SUCCESS : Status.ERROR}
            />
            <CustomSnackbar
              open={
                creatingSubBusiness &&
                (subBusiness.createSubBusinessSuccess || subBusiness.createSubBusinessErrorMessage !== null)
              }
              message={
                subBusiness.createSubBusinessSuccess
                  ? 'Comercio creado correctamente'
                  : 'Ocurrió un error al crear el comercio'
              }
              handleClose={closeSnack}
              type={subBusiness.createSubBusinessSuccess ? Status.SUCCESS : Status.ERROR}
            />
          </div>
        );
      }}
    </Formik>
  );
}

export default SubBusiness;
