import { Divider, IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewIcon } from '../../components/Icons/ViewIcon';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { getColumnMap } from '../../helpers/filteringHelper';
import { linkTypeEnumHelper } from '../../helpers/linkTypeEnumHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { getPaymentsHeadCells, paymentColumnMap } from '../../helpers/tableHelper';
import { fetchClients } from '../../store/action_creators/clients.actions';
import { loadPaymentsFilters } from '../../store/action_creators/payments.actions';
import { PaymentStatusEnum, PaymentTypeEnum, SortEnum, UserTypeEnum } from '../../store/config/enums';
import { Payment, RootState } from '../../store/config/types';
import CustomTable from '../CustomTable/CustomTable';
import { EmptyMessage } from '../EmptyMessage';
import { AdminCrown } from '../Icons/AdminCrown';
import { SplitIcon } from '../Icons/SplitIcon';
import { Loading } from '../Loading';
import { StatusPill } from '../StatusPill';
import CustomPagination from './CustomPagination';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    names: {
      textAlign: 'center',
      border: 'none',
      fontSize: '0.875rem',
      borderBottom: 'none',
      padding: '0.25rem',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    divider: {
      margin: '0 0.5rem',
      alignSelf: 'center',
      height: '1.6rem',
    },
    splitPayment: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      marginLeft: '1.5rem',
    },
  }),
);

export interface ExtraColumns {
  actions?: JSX.Element;
}

export type PaymentColumn = Payment & ExtraColumns;
interface PaymentsTableProps {
  maxItems?: number;
  showPlexoNames?: boolean;
}

function PaymentsTable({ maxItems, showPlexoNames }: PaymentsTableProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { auth, payments, clients } = useSelector((state: RootState) => state);

  const totalItems = payments.pageCount;
  const itemsPerPage = payments.pageSize;
  const isSuperAdmin = auth.account?.type === UserTypeEnum.SUPERADMIN;

  const [currentPage, setCurrentPage] = useState<number>(payments.currentPage || 1);

  const isDashboardTable = location.pathname === '/dashboard';

  let filteredPayments = payments?.payments;

  if (isDashboardTable) {
    filteredPayments =
      payments?.dashboardPayments?.filter((payment) => payment?.status === PaymentStatusEnum.APPROVED) ||
      null;
  }

  const hasSplitPayments = filteredPayments?.some((payment) => payment.type === PaymentTypeEnum.Split);

  const renderCustomTableCells = (row: Payment, columnId: keyof PaymentColumn) => {
    switch (columnId) {
      case 'date':
        return dateHelper.toShorterNiceString(row.date);
      case 'businessName':
        // TODO: Uncomment when showPlexoNames is returned from the backend remove the find and use the commented line
        // return showPlexoNames? row.plexoCommerceName : row.businessName;
        return showPlexoNames
          ? clients.clients?.find((client) => client.id === row.businessId)?.plexoCommerceName
          : row.businessName;
      case 'linkType':
        return (
          <div className={hasSplitPayments ? classes.splitPayment : ''}>
            {linkTypeEnumHelper.getIcon(row.linkType)}
            {row.type === PaymentTypeEnum.Split && (
              <>
                <Divider className={classes.divider} orientation="vertical" />
                <SplitIcon />
              </>
            )}
          </div>
        );

      case 'issuerLogoUrl':
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={row.issuerLogoUrl} alt="Issuer" style={{ marginRight: 4, height: '1.4375rem' }} />
            <p>•• {row.maskedPan.slice(-4)}</p>
          </div>
        );
      case 'amount':
        return (
          <>
            <span>{currencyHelper.getCurrencyToken(row.amount.currency)}</span>
            <span>
              {numberHelper.getNumberWithDots(row.amount.value + (row.splitPayment?.amount.value || 0))}
            </span>
          </>
        );
      case 'userDto':
        return row.userDto ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              justifyContent: 'center',
              gap: 4,
            }}
          >
            {row.userDto?.type === UserTypeEnum.OWNER && <AdminCrown />}
            <p className={classes.names}>{`${row.userDto?.names[0]}. ${row.userDto?.surnames}`}</p>
          </div>
        ) : (
          <p>-</p>
        );
      case 'status':
        return (
          <StatusPill
            label={paymentStatusEnumHelper.getStatusText(row.status)}
            color={paymentStatusEnumHelper.getStatusColorVariant(row.status)}
          />
        );
      case 'invoiceNumber':
        return row.invoiceNumber ? row.invoiceNumber : '-';
      case 'actions':
        return (
          <IconButton
            className={classes.icon}
            disableRipple
            onClick={() =>
              navigate(`/payments/${row.id}`, {
                state: { from: location.pathname },
              })
            }
          >
            <ViewIcon />
          </IconButton>
        );

      default:
        return row[columnId];
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    dispatch(loadPaymentsFilters(payments.filters, page, itemsPerPage, auth.account?.business?.id));
  };

  useEffect(() => {
    if (!clients.loadingClients && clients.clients === null && clients.loadingClientsErrorMessage === null) {
      dispatch(fetchClients());
    }
  });

  const dispatchSortChange = (columnId: keyof Payment) => {
    const isAsc = payments.filters.sortDirection === SortEnum.ASC;
    const newOrderBy = isAsc ? SortEnum.DESC : SortEnum.ASC;
    const paymentColumnEnum = getColumnMap(paymentColumnMap, columnId);
    setCurrentPage(1);

    dispatch(
      loadPaymentsFilters(
        {
          ...payments.filters,
          columnSort: paymentColumnEnum,
          sortDirection: newOrderBy,
        },
        1,
        itemsPerPage,
        auth.account?.business.id!,
      ),
    );
  };

  const rowsData: Payment[] | undefined = filteredPayments?.slice(0, maxItems).map((payment) => ({
    ...payment,
    date: payment.date,
    description: payment.description,
    collaborator: payment.userDto,
    linkType: payment.linkType,
    issuerLogoUrl: payment.issuerLogoUrl,
    maskedPan: payment.maskedPan,
    businessName: payment.businessName,
    amount: payment.amount,
    invoiceNumber: payment.invoiceNumber,
    status: payment.status,
  }));

  const emptyMessage = (
    <EmptyMessage
      title={!payments?.dashboardPayments ? 'Aún no tienes pagos.' : 'No se encontraron pagos'}
      message={
        !payments?.dashboardPayments
          ? 'Podrás visualizarlos aquí cuando recibas algún pago.'
          : 'No se encontraron pagos con los filtros seleccionados.'
      }
    />
  );

  return (
    <div className="payments-list">
      {!payments.loadingPayments && !clients.loadingClients ? (
        <CustomTable
          headCells={getPaymentsHeadCells(isDashboardTable, isSuperAdmin)}
          rowsData={rowsData || []}
          renderCell={renderCustomTableCells}
          emptyDataMessage={emptyMessage}
          sortable={{
            order: payments.filters.columnSort,
            direction: payments.filters.sortDirection || SortEnum.ASC,
            onRequestSort: dispatchSortChange,
          }}
        />
      ) : (
        <Loading />
      )}
      {!isDashboardTable && payments.payments && payments.payments?.length !== 0 && (
        <CustomPagination
          total={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
}

export default PaymentsTable;
