import {
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SplitIcon from '../../assets/SplitIcon';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import NewLinkHeader from '../../components/Link/Header';
import { RegularLink } from '../../components/Link/RegularLink';
import { SplitLink } from '../../components/Link/SplitLink';
import { dateHelper } from '../../helpers/dateHelper';
import { paymentTypeHelper } from '../../helpers/paymentTypeHelper';
import { fetchPaymentMethods } from '../../store/action_creators/paymentMethods.actions';
import { LinkTypeEnum, PaymentTypeEnum, Status } from '../../store/config/enums';
import { AuthState, Link as LinkType, LinksState, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '56.25rem',
      margin: '0 auto',
      paddingBottom: '1.5rem',
    },
    paperRoot: {
      margin: '0',
      marginTop: '1.625rem',
      padding: '1.875rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'space-between',
      color: styles.slateBlue,
    },
    contentTitle: {
      color: styles.slateBlue,
      fontSize: '1rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: '0 0 1rem 0',
    },
    paymentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      margin: '0.75rem 0',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.25rem',
      },
    },
    contentTitleHeader: {
      color: styles.slateBlue,
      fontSize: '0.875rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
    },
    contentTitleDescription: {
      display: 'inline-flex',
      gap: '0.375rem',
      color: styles.black,
      fontSize: '0.875rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
      '& span': {
        display: 'flex',
        alignSelf: 'center',
      },
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '1rem',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& div': {
          marginTop: '1rem',
        },
      },
      '& p': {
        margin: 0,
        fontWeight: Number(styles.semiBoldTextWeight),
      },
      '& span': {
        fontWeight: Number(styles.normalTextWeight),
      },
    },
    radioOptionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '1.375rem',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    radioOption: {
      border: `1px solid ${styles.dividerHorizontal}`,
      padding: '1.25rem',
      borderRadius: '0.3125rem',
      width: '50%',
      '& .MuiRadio-root': {
        alignSelf: 'flex-start',
        paddingTop: 0,
        color: styles.dividerHorizontal,
      },
      '& .MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    selectedOption: {
      borderColor: styles.primary,
      '& .MuiRadio-root': {
        color: styles.primary,
      },
    },
    paymentTypeOption: {
      fontSize: '0.875rem',
      color: styles.slateBlue,
      '& p': {
        margin: 0,
        fontSize: '0.875rem',
        color: styles.iconColor,
      },
      '& p:first-child': {
        display: 'flex',
        gap: '0.375rem',
        color: styles.slateBlue,
        '& span': {
          display: 'flex',
          alignSelf: 'center',
        },
      },
    },
    paymentSwitchContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& p': {
        margin: 0,
      },
    },
    error: {
      color: styles.red,
    },
    paymentMethodContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '2%',
      width: '100%',
    },
  }),
);

function Link() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const linkType = location.pathname.includes('one-use') ? LinkTypeEnum.ONETIME : LinkTypeEnum.PERMANENT;
  const link = (location.state?.link as LinkType) ?? null;
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const links: LinksState = useSelector((state: RootState) => state.links);
  const businessId = auth.account?.business.id!;
  const businessAcceptsSplitPayments = auth.account?.business.acceptsSplitPayments;
  const [creatingLink, setCreatingLink] = useState<boolean>(false);
  const [updatingLink, setUpdatingLink] = useState<boolean>(false);
  const [paymentMethodsIds, setPaymentMethodsIds] = useState<number[]>(
    link && link.installmentsDto ? link.installmentsDto.map((installment) => installment.id) : [],
  );
  const [clickedCopy, setClickedCopy] = useState<boolean | null>(null);
  const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(
    link ? link.paymentType : PaymentTypeEnum.Regular,
  );

  useEffect(() => {
    dispatch(fetchPaymentMethods(businessId));
  }, [businessId, dispatch]);

  useEffect(() => {
    if (clickedCopy && links.createLinkSuccess && links.createLinkErrorMessage === null && links.latestLink) {
      navigator.clipboard.writeText(links.latestLink.url ?? '');
    }
  }, [clickedCopy, links.createLinkSuccess, links.createLinkErrorMessage, links.latestLink]);

  useEffect(() => {
    if (clickedCopy && links.updateLinkSuccess && links.updateLinkErrorMessage === null && links.latestLink) {
      navigator.clipboard.writeText(links.latestLink.url ?? '');
    }
  }, [clickedCopy, links.updateLinkSuccess, links.updateLinkErrorMessage, links.latestLink]);

  const paymentTypeLabel = (paymentType: PaymentTypeEnum) => {
    return (
      <div className={classes.paymentTypeOption}>
        <p>
          {paymentType === PaymentTypeEnum.Split && (
            <span>
              <SplitIcon />
            </span>
          )}
          <span>{paymentTypeHelper.paymentTypesTitle[paymentType]}</span>
        </p>
        <p>{paymentTypeHelper.paymentTypesSubtitle[paymentType]}</p>
      </div>
    );
  };

  const handlePaymentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) as PaymentTypeEnum;
    setPaymentType(value);
  };

  const closeSnack = () => {
    if (link) {
      setUpdatingLink(false);
      if (links.updateLinkSuccess) navigate(-1);
    } else {
      setCreatingLink(false);
      if (links.createLinkSuccess) navigate(-1);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <NewLinkHeader
          setClickedCopy={setClickedCopy}
          link={link}
          linkType={linkType}
          creatingLink={creatingLink}
          updatingLink={updatingLink}
          clickedCopy={clickedCopy}
          paymentMethodsIds={paymentMethodsIds}
        />
        {(businessAcceptsSplitPayments || link) && (
          <Paper className={classes.paperRoot}>
            <div className={classes.titleContainer}>
              <p className={classes.contentTitle}>
                {link ? 'Detalle del link' : 'Elige cómo quieres cobrar'}
              </p>
            </div>
            {link ? (
              <>
                <div className={classes.paymentHeader}>
                  <p className={classes.contentTitleHeader}>Fecha y hora de creación del link</p>
                  <p className={classes.contentTitleDescription}>
                    {dateHelper.toShorterNiceString(new Date(link.date))}
                  </p>
                </div>
                {businessAcceptsSplitPayments && (
                  <div className={classes.paymentHeader}>
                    <p className={classes.contentTitleHeader}>Tipo de pago</p>
                    <p className={classes.contentTitleDescription}>
                      {paymentType === PaymentTypeEnum.Split && (
                        <span>
                          <SplitIcon />
                        </span>
                      )}
                      <span>{paymentTypeHelper.paymentTypesTitle[paymentType]}</span>
                    </p>
                  </div>
                )}
              </>
            ) : (
              <RadioGroup
                value={paymentType}
                onChange={handlePaymentTypeChange}
                row
                aria-label="payment-method"
                className={classes.radioOptionsContainer}
              >
                <FormControlLabel
                  value={PaymentTypeEnum.Regular}
                  control={<Radio />}
                  label={paymentTypeLabel(PaymentTypeEnum.Regular)}
                  className={`${classes.radioOption}
            ${paymentType === PaymentTypeEnum.Regular ? classes.selectedOption : ''}
            `}
                  disabled={updatingLink}
                />
                <FormControlLabel
                  value={PaymentTypeEnum.Split}
                  control={<Radio />}
                  label={paymentTypeLabel(PaymentTypeEnum.Split)}
                  className={`${classes.radioOption}
            ${paymentType === PaymentTypeEnum.Split ? classes.selectedOption : ''}
            `}
                  disabled={updatingLink}
                />
              </RadioGroup>
            )}
          </Paper>
        )}
        {paymentType === PaymentTypeEnum.Regular ? (
          <RegularLink
            setClickedCopy={setClickedCopy}
            clickedCopy={clickedCopy}
            setCreatingLink={setCreatingLink}
            creatingLink={creatingLink}
            setUpdatingLink={setUpdatingLink}
            updatingLink={updatingLink}
            paymentMethodsIds={paymentMethodsIds}
            setPaymentMethodsIds={setPaymentMethodsIds}
            commonClasses={classes}
          />
        ) : (
          <SplitLink
            setClickedCopy={setClickedCopy}
            clickedCopy={clickedCopy}
            setCreatingLink={setCreatingLink}
            creatingLink={creatingLink}
            setUpdatingLink={setUpdatingLink}
            updatingLink={updatingLink}
            paymentMethodsIds={paymentMethodsIds}
            setPaymentMethodsIds={setPaymentMethodsIds}
            commonClasses={classes}
          />
        )}
      </div>
      <CustomSnackbar
        open={creatingLink && (links.createLinkSuccess || links.createLinkErrorMessage !== null)}
        message={
          links.createLinkSuccess
            ? `Se creó ${clickedCopy ? 'y copió ' : ''}el link correctamente`
            : links.createLinkErrorMessage!
        }
        handleClose={closeSnack}
        type={links.createLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
      <CustomSnackbar
        open={updatingLink && (links.updateLinkSuccess || links.updateLinkErrorMessage !== null)}
        message={
          links.updateLinkSuccess ? 'Se actualizó el link correctamente' : links.updateLinkErrorMessage!
        }
        handleClose={closeSnack}
        type={links.updateLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default Link;
