import { Grid, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISSUERS_WITHOUT_INSTALLMENTS } from '../../../data/constants';
import schemas from '../../../data/schemas';
import { createPaymentMethodForSubBusiness } from '../../../store/action_creators/subBusiness.actions';
import {
  BasePaymentMethod,
  CreatePaymentMethodForSubBusinessRequest,
  Issuer,
  PaymentMethod,
  RootState,
  SubBusiness,
} from '../../../store/config/types';
import PlexoFieldsForSubBusiness from '../../dialogs/PaymentMethodDialog/PlexoFieldsForSubBusiness';
import IssuerAndAcquirer from './IssuerAndAcquirer';
import SoftDescriptor from './SoftDescriptor';
import SubmitButton from './SubmitButton';

interface Values extends Omit<BasePaymentMethod, 'installments' | 'crossBankTransfers'> {
  issuer: string;
  acquirer: string;
  installments: number[];
  crossBankTransfers: boolean;
  commerceNumber: string;
  terminalNumber: string;
}

interface SubBusinessPaymentMethodFormProps {
  handleSuccess: () => void;
  paymentMethod: PaymentMethod | null;
  addPaymentMethodToSubBusiness: (paymentMethod: Values, isAlreadyInState: boolean) => void;
  selectedSubBusiness: SubBusiness;
}

function SubBusinessPaymentMethodForm({
  paymentMethod,
  handleSuccess,
  addPaymentMethodToSubBusiness,
  selectedSubBusiness,
}: SubBusinessPaymentMethodFormProps) {
  const { auth, paymentMethods } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const selectedInstallments = paymentMethod ? paymentMethod.values : [1];
  const isIssuerWithoutInstallments = (selectedIssuer: Issuer | null): boolean => {
    return selectedIssuer ? ISSUERS_WITHOUT_INSTALLMENTS.includes(selectedIssuer?.issuerId) : false;
  };
  const [addingPaymentMethod, setAddingPaymentMethod] = useState<boolean>(false);

  const businessId = auth.account?.business.id;

  const submitPaymentMethod = (values: Values, helpers: FormikHelpers<Values>) => {
    setAddingPaymentMethod(true);
    if (selectedSubBusiness) {
      const createPaymentMethodForSubBusinessRequest: CreatePaymentMethodForSubBusinessRequest = {
        softDescriptor: values.softDescriptor ? values.softDescriptor : '',
        commerceNumber: values.commerceNumber ? values.commerceNumber : '',
        terminalNumber: values.terminalNumber ? values.terminalNumber : '',
        issuerId: +values.issuer,
        businessId: businessId!,
        installments: values.installments,
        acquirer: values.acquirer,
        subBusiness: {
          id: selectedSubBusiness.id,
          name: selectedSubBusiness.name,
          plexoCommerceId: selectedSubBusiness.plexoCommerceId,
          plexoCommerceName: selectedSubBusiness.plexoCommerceName,
        },
      };
      dispatch(createPaymentMethodForSubBusiness(createPaymentMethodForSubBusinessRequest));
    }
    addPaymentMethodToSubBusiness(values, false);
    handleSuccess();
    setAddingPaymentMethod(false);
  };

  const renderContent = (noInstallmentsIssuer: boolean, selectedIssuer: Issuer | undefined, fields: any) => (
    <>
      <Typography variant="h6" className={`form-title`}>
        Agrega un primer medio de pago al comercio
      </Typography>
      <Grid container spacing={2}>
        <IssuerAndAcquirer
          paymentMethod={paymentMethod}
          installmentsField={false}
          selectedIssuer={selectedIssuer!}
        />
        <SoftDescriptor />
        <PlexoFieldsForSubBusiness fields={fields} paymentMethod={paymentMethod} />
      </Grid>
    </>
  );

  return (
    <Formik
      initialValues={{
        issuer: paymentMethod ? paymentMethod.issuerId.toString() : '',
        acquirer: '',
        installments: selectedInstallments,
        softDescriptor: '',
        accountNumber: (paymentMethod && paymentMethod.fields?.accountNumber) || '',
        commerceNumber: '',
        terminalNumber: '',
        crossBankTransfers:
          (paymentMethod && paymentMethod.fields?.crossBankTransfers === 'True' ? true : false) || false,
        rut: (paymentMethod && paymentMethod.fields?.rut) || '',
        merchantCategoryCode: '',
      }}
      validationSchema={schemas.PaymentMethodSchema}
      onSubmit={submitPaymentMethod}
      enableReinitialize={true}
    >
      {({ values }) => {
        const selectedIssuer =
          paymentMethods.issuers && paymentMethods.issuers.filter((i) => i.id === values.issuer)[0];

        const selectedAcquirer = selectedIssuer?.paymentProcessors
          ? selectedIssuer?.paymentProcessors.filter((pp) => pp.acquirer === values.acquirer)[0]
          : '';

        const fields = selectedAcquirer
          ? selectedAcquirer.fields
          : !selectedIssuer || !selectedIssuer.paymentProcessors
          ? selectedIssuer?.fields
          : paymentMethod
          ? selectedIssuer?.fields
          : [];

        const noInstallmentsIssuer = isIssuerWithoutInstallments(selectedIssuer);

        const isSubmitButtonDisabled =
          (!paymentMethod && !values.softDescriptor) || !values.acquirer || addingPaymentMethod;

        return (
          <Form className="form payment-method-form" id="payment-method-form">
            {renderContent(noInstallmentsIssuer, selectedIssuer!, fields)}
            <SubmitButton
              paymentMethod={paymentMethod}
              disabled={isSubmitButtonDisabled}
              creatingCommerce={false}
              updatingCommerce={false}
              isRegisterStep={false}
              loading={addingPaymentMethod}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default SubBusinessPaymentMethodForm;
